import React from 'react'
import './ProbaComponens.css';
export const ProbaComponens = () => {
    return (
        <div>
            <h1>Ez a próbaCompones. Ami egy alkomponens</h1>
            <h2 className="kek">a szine a próbaCompones.css ből jön</h2>
        </div>
    )
}

export default ProbaComponens;
