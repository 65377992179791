import  ProbaComponens from '../src/ProbaComponens/ProbaComponens';
import './App.css';

function App() {
  return (
    <div>
    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    <h1>Ez a főkomponens.</h1>
    <h2 className="piros">Ennek színe az App.css ből származik.
    Pirosnak kellene lennie.</h2>
    Itt egy importált komponens:
    <ProbaComponens/>
    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    </div>
  );
}

export default App;
