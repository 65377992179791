import './Alkomp.css';

function Alkomp() {
  return (
    <div>
    <h1>Ez a főkomponens.</h1>
    <h2 className="lila">Ennek színe az App.css ből származik.</h2>
    Itt egy ijmportált komponens:
    </div>
  );
}

export default Alkomp;