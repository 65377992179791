import React from 'react';
import './Cookies.css';
import CookieConsent from "react-cookie-consent";


const Cookies2 = props => {
      


function CookieCleaner() {
    console.log('ez egy funkció2');
    window['ga-disable-UA-158844454-5'] = true;
    console.log('ez egy funkció2');
}  

      return (
          <div className="CookiesEgeszKulso">

<CookieConsent
buttonText="Elfogadom"
 onDecline={() => {
   alert("nay!");
   CookieCleaner();
 }}

>Az oldal cookie-kat használ, az oldal további használatával elfogadja ezeket.
Részletek az Adatvédelmi tájékoztatóban.
</CookieConsent>
{/*
<button onClick={CookieCleaner}>Izé</button>*/}

        </div>
      )
}
export default Cookies2;