import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MasikApp from './MasikApp/MasikApp';
import Cookies2 from './Cookies/Cookies';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

ReactDOM.render(
  <React.StrictMode>
    <MasikApp />
  </React.StrictMode>,
  document.getElementById('root2')
);

ReactDOM.render(
  <React.StrictMode>
    <Cookies2/>
  </React.StrictMode>,
  document.getElementById('cookies')
);

/*
ReactDOM.render(<App/>,document.getElementById('root'));

ReactDOM.render(<MasikApp />,document.getElementById('root2'));
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
