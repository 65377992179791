import Alkomp from '../MasikApp/AlKomp/Alkomp'
import './MasikApp.css';

function MasikApp() {
  return (
    <div>
    <h1>MasikApp</h1>
    <h2 className="piros">Ennek színe zöld ha sikerül felülírni
    és így elkülönül a másiktól. A MasikApp.css ből származik.</h2>
    Itt egy ijmportált komponens:
    <Alkomp/>
    </div>
  );
}

export default MasikApp;
